<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  created() {
    this.updateNavbar({ showGoalPicker: false, showDatePicker: false })
  },
  methods: {
    ...mapActions('appConfig', {
      updateNavbar: 'updateNavbarConfig',
    }),
  },
}
</script>
